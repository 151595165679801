import { render, staticRenderFns } from "./Deathlist.vue?vue&type=template&id=36d94f17&scoped=true&"
import script from "./Deathlist.vue?vue&type=script&lang=js&"
export * from "./Deathlist.vue?vue&type=script&lang=js&"
import style0 from "./Deathlist.vue?vue&type=style&index=0&id=36d94f17&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d94f17",
  null
  
)

export default component.exports